<template>
  <div>
    <vx-card :title="$t('log.title')" class="items-center">
      <vs-row>
        <!-- 搜尋 -->
        <vs-col vs-type="flex" vs-justify="flex-end">
          <!-- 選開始日期 -->
          <el-date-picker
            v-model="query_start_date_time"
            type="date"
            :placeholder="$t('log.startDate')"
            value-format="yyyy-MM-dd"
            align="center">
          </el-date-picker>
          <!-- 選結束日期 -->
          <el-date-picker
            class="sm:mx-2 mx-1"
            v-model="query_end_date_time"
            type="date"
            :placeholder="$t('log.endDate')"
            value-format="yyyy-MM-dd"
            align="center">
          </el-date-picker>
          <!-- 搜尋按鈕 -->
          <vs-button class="ml-2" type="flat" radius icon="search" :disable="query_start_date_time==null && _self.query_end_date_time==null" @click="logdatafilter"></vs-button>
        </vs-col>
        <!-- list -->
        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-list v-if="log.length!=0">
            <vs-list-item :class="windowWidth<768 ? 'cd-list-item-sm w-full' : ''" :title="item.feature_name" :subtitle="item.description" v-for="(item, index) in log" :key="index">
              <vs-row>
                <vs-col vs-type="flex" vs-justify="flex-end">
                  <p class="cd-date">{{item.created_at}}</p>
                </vs-col>
              </vs-row>
            </vs-list-item>
          </vs-list>
          <!-- 無資料 -->
          <vs-col class="mt-10" vs-type="flex" vs-justify="center" v-else>
            <h4 class="justify-content-between">{{$t('log.noData')}}</h4>
          </vs-col>
        </vs-col>
      </vs-row>
      <!-- 分頁 -->
      <vs-row>
        <vs-col vs-type="flex" vs-justify="center">
          <vs-pagination :max="windowWidth<768 ? 5 : 7" :total="Math.ceil(logtotal/20)" v-model="page" @change="fetchMylog(page)"></vs-pagination>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      query_start_date_time: null,
      query_end_date_time: null
    }
  },
  computed: {
    log() {
      return this.$store.state.log
    },
    logsearch() {
      return this.$store.state.logsearch
    },
    logsearchpayload() {
      return this.$store.state.logsearchpayload
    },
    logpage(){
        return this.$store.state.logpage
    },
    logtotal() {
      return this.$store.state.logtotal
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
  },
  mounted() {
    this.fetchMylog(1)
  },
  methods: {
    // 取log
    fetchMylog(logpage){
      let _self = this
      if(_self.logsearch==false){
        _self.$store.dispatch('fetchMyLog',{page:logpage})
        _self.$store.commit('SET_LOGPAGE', logpage)
      }else{
        _self.logsearchpayload['page'] = logpage
        _self.$store.dispatch('fetchMyLog',_self.logsearchpayload)
      }
      window.scrollTo(0,0);
    },
    // 搜尋log資料
    logdatafilter(){
      var _self = this
      if(_self.query_start_date_time!=null && _self.query_end_date_time!=null){
        _self.$store.dispatch('fetchMyLog',{start_time:_self.query_start_date_time, end_time:_self.query_end_date_time})
        _self.$store.commit('SET_LOGPAGE', 1)
        _self.$store.commit('SET_LOGSEARCH', true)
        _self.$store.commit('SET_LOGSEARCHPAYLOAD',{start_time:_self.query_start_date_time, end_time:_self.query_end_date_time})
      }
      else{
        _self.$store.dispatch('fetchMyLog')
        _self.$store.commit('SET_LOGPAGE', 1)
      }
    }
  }
}
</script>
